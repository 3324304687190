<template>
    <div>
        <ueber-uns/>
        <div class="mx-2">
          <dienstleistungen />
          <sanierung/>
          <spritzverfugung/>
          <bauen/>
          <mauerwerk />
          <!-- <sonstige/> -->
          <kernbohrungen />
          <holzrestaurierung />
          <aktion />
        </div>
        <kontaktformular />
    </div>
</template>

<script>
import UeberUns from './00_UeberUns.vue';
import Sanierung from './01_Sanierung.vue';
import Spritzverfugung from './02_Spritzverfugung.vue';
import Bauen from './03_Bauen.vue';
//import Sonstige from './03a_Sonstige.vue'
import Dienstleistungen from './04_Dienstleistungen.vue';
import Kernbohrungen from './05_Kernbohrungen.vue';
import Holzrestaurierung from './06_Holzrestaurierung.vue';
import Aktion from './06a_InAktion.vue';
import Mauerwerk from './06b_Mauerwerk.vue';
import Kontaktformular from './07_Kontaktformular.vue';

export default {
  name: 'App',

  components: {
    UeberUns,
    Sanierung,
    Spritzverfugung,
    Bauen,
    //Sonstige,
    Dienstleistungen,
    Kernbohrungen,
    Holzrestaurierung,
    Mauerwerk,
    Aktion,
    Kontaktformular
  },

  data: () => ({
  })

};
</script>
