<template>
    <div id="holzrestaurierung" class="pt-8">
        <v-container class="mt-5 mb-5">
            <h1>Das Fesch-Bau Team in Aktion</h1>
            <p>Mit unserem Maschinenpark und einem hochmotivierten Team können wir auch auf kleinstem Raum effektiv arbeiten. </p>
            
        <v-layout row wrap class="mt-2">
            <v-flex v-for="item in serviceItems" :key="item.description" xs10 offset-xs1 offset-sm0 sm6 lg3 class="py-2">
            <v-card
                class="ma-2"
                height="100%"
            >
                <v-img
                :src="item.source"
                ></v-img>

                <v-card-text>
                        {{ item.description }}
                    </v-card-text>

            </v-card>
            </v-flex>
        </v-layout>

        </v-container>
    </div>
</template>

<script>
  export default {
    data: () => ({
     serviceItems: [
       {description: 'Stemmarbeiten mit einem Minibagger', 
       source: require('../assets/inaktion1.jpg')},
       //Während die Schadstellen an den Ausfachungen eher ins Auge fallen, werden zum Beispiel schleichende Fäulnisprozesse am Holz oft erst nach Jahren erkannt.
       {description: 'Abbruch und Transport von Bauschutt mit einem Dumper im Gebäude', 
       source: require('../assets/inaktion2.jpg')},
       {description: 'Betontransport mit einem Dumper', 
       source: require('../assets/inaktion3.jpg')},
       //In solchen Fällen kann Abhilfe nur durch einen ausgewiesenen Spezialisten erfolgen.
       {description: 'Einbau von Beton mit einem Dumper', 
       source: require('../assets/inaktion4.jpg')},
     ]
    }),
  }
</script>