import Vue from 'vue'
import Router from 'vue-router'
import MainPage from './components/Main.vue'
import ImprintPage from './components/ImprintPage.vue'
import DataPage from './components/DataPrivacyPage.vue'
//import RequestPage from './components/10_DigitalesAngebot.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage
    },
    {
      path: '/impressum',
      name: 'ImprintPage',
      component: ImprintPage
    },
    {
      path: '/datenschutz',
      name: 'DataPage',
      component: DataPage
    },
    /*{
      path: '/anfrage',
      name: 'RequestPage',
      component: RequestPage
    }*/
  ]
})

