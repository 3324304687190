var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-2 my-10 pt-16",attrs:{"id":"kontakt"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-lg":"3","lg":"6","offset-md":"2","md":"8","offset-sm":"1","sm":"10"}},[_c('v-card',{staticClass:"mx-auto pa-2 my-2",attrs:{"height":"100%","align":"center","justify":"center"}},[_c('h1',[_vm._v("Wie können wir weiterhelfen?")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-container',{staticStyle:{"position":"relative"}},[_c('v-overlay',{attrs:{"value":_vm.overlayForm}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-account","error-messages":errors,"label":"Name","placeholder":"Erika Mustermann","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-phone","error-messages":_vm.errors,"label":"Tel. (optional)","placeholder":"05656 311"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-email","error-messages":errors,"label":"E-Mail","placeholder":"erika.mustermann@fesch-bau.de","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-textarea',{attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-pencil","color":"teal","label":"Ihre Nachricht","placeholder":"Guten Tag, ich habe eine Rückfrage zu ..."},model:{value:(_vm.text_message),callback:function ($$v) {_vm.text_message=$$v},expression:"text_message"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"Checkbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"required":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Ich habe die "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticStyle:{"color":"inherit"},attrs:{"target":"_blank","href":"/datenschutz"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Datenschutzerklärung ")])]}}],null,true)},[_vm._v(" Datenschutzerklärung lesen ")]),_vm._v(" zur Kenntnis genommen. ")],1)]},proxy:true}],null,true),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" Senden ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" Zurücksetzen ")])],1)],1),(_vm.formSuccessful)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"size":"40px","color":"green"}},[_vm._v("mdi-check-circle")])],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" Ihre Informationen wurden erfolgreich übertragen. Wir melden uns in Kürze bei Ihnen. ")])],1):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }