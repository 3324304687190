<style>
#ifpPage {
    position: relative;
    background: none;
    overflow: hidden; 
    min-height: 50%;
    /*max-height: 60vh;*/
    width: 100%; 
}

#ifpPage div{
  position:relative;
  z-index:2;
  color: white;
}

#ifpPage:before{
  content: ' ';
  display: block;
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background: url('../assets/fb_willkommen.jpg');
  background-repeat: no-repeat; 
  background-size: cover;
  background-position: 50% 35%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>

<template>
  <div>
      <v-container>
        <v-row :style="topContainerHeight">
          <v-col offset-xl="2" xl="8" id="ifpPage"></v-col>
        </v-row>
      </v-container>
      <v-container class="mt-6">
        <v-row>
          <v-col offset-xl="2" xl="8">
            <p style="font-size: 20px; text-align: center;">Kompetenz, Verlässlichkeit und Qualität bei der Leistungserstellung gehören zu unserem wichtigsten Handwerkszeug. Wir reagieren schnell auf Ihre individuellen Wünsche. Dabei werden Sie unsere direkte Kommunikation und unser Einfühlungsvermögen schätzen lernen. Die Realisierung Ihres Vorhabens liegt bei uns in besten Händen. </p> 
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>


export default {
  
  data: () => ({
    //
  }),

  computed: {
      /*topContainerHeight () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '1000px'
          case 'sm': return '1000px'
          case 'md': return '200px'
          case 'lg': return '200px'
          case 'xl': return '200px'
        }
      },*/
      topContainerHeight () {
        var height="height: 0vh;"
        if (this.$vuetify.breakpoint.name == 'xs') {
          height="height: 50vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'sm') {
          return "height: 50vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'md') {
          return "height: 60vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'lg') {
          return "height: 60vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'xl') {
          return "height: 40vh;"
        } 
        return height
        /*if (this.$vuetify.breakpoint.name == 'md') return "20vh"
        if (this.$vuetify.breakpoint.name == 'lg') return "20vh"
        if (this.$vuetify.breakpoint.name == 'xl') return "20vh"*/
      },
  }

};
</script>
