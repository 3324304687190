<template>
    <div id="spritzverfugung" class="pt-8">
        <v-container>
            <h1>Spritzverfugung</h1>
            <v-row>
                <v-col>
                    Bei der Spritzverfugung handelt es sich um ein maschinelles Verfugen von Natursteinmauerwerk. Der Mörtel wird maschinell mit einer Düse auf die Wand aufgebracht.
                </v-col>
            </v-row>
        
        <v-layout row wrap class="mt-2">
            <v-flex v-for="item in serviceItems" :key="item.description" xs10 offset-xs1 offset-sm0 sm6 lg3 class="py-2">
            <v-card
                class="ma-2"
                height="100%"
            >
                <v-img
                :src="item.source"
                ></v-img>

                <v-card-text>
                    {{ item.description }}
                </v-card-text>
            </v-card>
            </v-flex>
        </v-layout>
        </v-container>
    </div>
</template>

<script>


  export default {
    data: () => ({
     serviceItems: [
       {description: 'Hier wird die Spritzverfugung an einer Stadtmauer ausgeführt.', 
       source: require('../assets/spritzverfugung1.png')},
       {description: 'Die Fugen werden dann mit dem Sandstrahlgerät schonend bearbeitet.', 
       source: require('../assets/spritzverfugung2.jpg')},
       {description: 'Dieses Gebäude wird im Trockenspritzverfahren verfugt.', 
       source: require('../assets/spritzverfugung3.jpg')},
       {description: 'Sicherung von historischem Mauerwerk an einer Burgruine', 
       source: require('../assets/spritzverfugung4.jpg')}
     ]
    }),
  }
</script>