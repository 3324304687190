<template>
    <div id="sanierung" class="pt-8">
        <v-container class="mt-5 mb-5">
            <h1>Sanierung und Erhaltung</h1>
        <v-row>
            <v-col col=12>
                Wir stellen uns jeden Tag den Wünschen unserer Kunden und bieten ein breites Spektrum von Bau- und Dienstleistungen. Die Sanierung und Erhaltung gehört zu den Schwerpunkten unserer Tätigkeit.
            </v-col>
        </v-row>

        <v-layout row wrap>
            <v-flex v-for="item in serviceItems" :key="item.description" xs10 offset-xs1 offset-sm0 sm6 lg3 class="py-2">
            <v-card
                class="ma-2"
                height="100%"
            >
                <v-img
                :src="item.source"
                ></v-img>

                <v-card-text>
                    {{ item.description }}
                </v-card-text>
            </v-card>
            </v-flex>
        </v-layout>

        </v-container>
    </div>
</template>

<script>
  export default {
    data: () => ({
     serviceItems: [
       {description: 'Dieses Beispiel zeigt die Rekonstruktion und Sanierung einer historischen Treppenanlage.', 
       source: require('../assets/sanierung1.jpg')},
       {description: 'Herstellung einer Vierung (Ergänzung einer fehlenden Treppenstufen-Ecke) an einer Klosteranlage', 
       source: require('../assets/sanierung2.jpg')},
       {description: 'Bei der Sanierung eines ehemaligen Mühlengebäudes wurden Maurer- und Natursteinarbeiten ausgeführt.', 
       source: require('../assets/sanierung3.jpg')},
       {description: 'Die Mauerwerksanierung an einer ehemaligen Remise ermöglicht eine zeitgemäße und vielfältige Nutzung.', 
       source: require('../assets/sanierung4.jpg')}
     ]
    }),
  }
</script>