<style>
#ifpPage {
    position: relative;
    background: none;
    overflow: hidden; 
    min-height: 50%;
    /*max-height: 60vh;*/
    width: 100%; 
}

#ifpPage div{
  position:relative;
  z-index:2;
  color: white;
}

#ifpPage:before{
  content: ' ';
  display: block;
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background: url('./assets/fb_willkommen.jpg');
  background-repeat: no-repeat; 
  background-size: cover;
  background-position: 50% 35%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>

<template>
  <v-app>
    <!-- for desktop size -->
    <v-toolbar 
      app 
      class="hidden-sm-and-down">
        <router-link to="/">
          <v-toolbar-title>
            <v-img
              src="@/assets/feschbauschrift.svg"
              height="40"
              width="100%"
              contain></v-img>
          </v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="!['ImprintPage', 'DataPage'].includes($route.name)">
         <v-btn
           v-for="item in nav"
           :key="item.icon"
           @click="$vuetify.goTo(item.to)"
           :title="item.title"
           
           flat
         >{{ item.text }}</v-btn>
        </v-toolbar-items>
        <v-toolbar-items v-if="['ImprintPage', 'DataPage'].includes($route.name)">
          <v-btn to="/impressum">Impressum</v-btn>
          <v-btn to="/datenschutz">Datenschutz</v-btn>
          <v-btn style="background-color: #F3A712" to="/">Zurück zur Hauptseite</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- for mobile size -->
      <v-app-bar app class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true">
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-btn color="#F3A712" @click="$vuetify.goTo('#kontakt')">Kontakt</v-btn>
      </v-app-bar>

    <v-navigation-drawer app
      v-model="drawer"
      temporary
      width="100vw"
    >
      <div style="text-align: right;" class="ma-2">
        <v-btn @click="drawer=false" color="red">X</v-btn>
      </div>
      
      <v-img
              src="@/assets/feschbauschrift.svg"
              height="40"
              contain
              class="mb-4"></v-img>

      
      <v-list
        nav
      >
        <v-list-item-group v-if="!['ImprintPage', 'DataPage'].includes($route.name)"
        >
          <v-list-item v-for="item in nav" :key=item.to>
            <v-list-item-title @click="$vuetify.goTo(item.to); drawer = false" style="font-size: 20px;">
              {{item.text}}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-if="['ImprintPage', 'DataPage'].includes($route.name)"
        >
          <v-list-item to="/impressum" @click="drawer=false">
            <v-list-item-title >
              Impressum
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/datenschutz" @click="drawer=false">
            <v-list-item-title>
              Datenschutz
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/" @click="drawer=false" style="background-color=#F3A712">
            <v-list-item-title>
              Zur Haupseite
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>        
      </v-list>
    </v-navigation-drawer>      

    <v-main>
      <div class="scroll-y">
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        
        <router-view/>
        
      </div>
    </v-main>
    <standard-footer></standard-footer>   
  </v-app>
</template>

<script>
import * as easings from 'vuetify/lib/services/goto/easing-patterns';
import StandardFooter from './components/StandardFooter.vue';

export default {
  name: 'App',

  components: {
    StandardFooter
  },

  data: () => ({
      dialog: false,
      nav: [
        {
          icon: 'mdi-home',
          text: 'Sanierung',
          title: 'Back to Home page',
          active: true,
          to: '#sanierung',
          color: '#bed9ee'
        },
        {
          icon: 'mdi-info',
          text: 'Spritzverfugung',
          title: 'About this demo',
          active: false,
          to: '#spritzverfugung',
          color: '#e9d1c5'
        },
        {
          icon: 'mdi-assignment',
          text: 'Bauen',
          title: 'Some stuff that needs doing',
          active: false,
          to: '#bauen',
          color: '#f7ecac'
        },
        /*{
          icon: 'mdi-email',
          text: 'Dienstleistungen',
          title: 'Our Contact info',
          active: false,
          to: '#dienstleistungen',
          color:'#e2e0ee'
        },*/
        /*{
          icon: 'mdi-email',
          text: 'Kernbohrungen',
          title: 'Our Contact info',
          active: false,
          to: '#kernbohrungen',
          color: '#eccddf'
        },*/
        {
          icon: 'mdi-email',
          text: 'Holzrestaurierung',
          title: 'Our Contact info',
          active: false,
          to: '#holzrestaurierung',
          color: '#c4e6e7'
        },
        {
          icon: 'mdi-email',
          text: 'Kontakt',
          title: 'Our Contact info',
          active: false,
          to: '#kontakt',
          color: '#e6878f'
        }
      ],
      type: 'number',
      number: 9999,
      duration: 300,
      offset: 0.1,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings),
      fab: false,
      drawer: false
  }),

  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },

  computed: {
      topContainerHeight () {
        var height="height: 0vh;"
        if (this.$vuetify.breakpoint.name == 'xs') {
          return "height: 50vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'sm') {
          return "height: 50vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'md') {
          return "height: 40vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'lg') {
          return "height: 25vh;"
        } 
        if (this.$vuetify.breakpoint.name == 'xl') {
          return "height: 25vh;"
        } 
        return height
      },
  }

};
</script>
