<template>
    <v-footer
      dark
      padless
      
    >

      <v-card
        style="background-color: #534D41;"
        flat
        tile
        class="white--text text-center"
        width="100%"
      >
        <v-card-text>
          <div>
              <v-btn
              v-for="icon in icons"
              :key="icon.logo"
              class="mx-2 white--text"
              icon
              :href="icon.to"
              target="_blank"
          >
              <v-icon size="30px">
              {{ icon.logo }}
              </v-icon>
          </v-btn>
          </div>
            
          <div class="mt-4">
            <span class="mx-2">
              <a style="color: inherit; text-decoration:none;" href="callto://+495656311">
                <v-icon size="20px">mdi-phone</v-icon>
                +49 5656 311
              </a>
            </span>
            
            <span class="mx-2">
              <a style="color: inherit; text-decoration:none;" href="mailto:email@fesch-bau.de">
                <v-icon size="20px">mdi-email</v-icon>
                email@fesch-bau.de
              </a>
            </span>
          </div>
    

        </v-card-text>


        <v-divider></v-divider>

        <v-card-text class="white--text">
          Fescher Bauen mit Fesch-Bau. Alle Rechte vorbehalten. <a style="color: inherit;" href="/impressum">Impressum</a> und <a style="color: inherit;" href="/datenschutz">Datenschutzerklärung</a>. <br>
          © {{ new Date().getFullYear() }} Fesch-Bau GmbH. 
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>

export default {
  data: () => ({
    
  }),
};
</script>
