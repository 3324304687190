<template>
    <div id="dienstleistungen" class="pt-8">
        
        <v-container class="mt-5 mb-5">
            <h1>Leistungen</h1>
            <p>Seit vielen Jahren führen wir mit unseren erfahrenen Mitarbeitern folgende Dienstleistungen- insbesondere im Gebäudebestand - aus:</p>
        
            <v-layout row wrap class="mt-2">
                <v-flex v-for="item in serviceItems" :key="item.description" xs12 offset-sm0 sm6 lg3 class="py-2">
                <v-card
                    class="ma-2"
                    height="100%"
                    :color="item.color"
                    @click="$vuetify.goTo(item.to)"
                >
                    <!--<v-img
                    :src="item.source"
                    ></v-img>-->

                    <v-card-title style="word-break: break-word">
                        {{ item.description }}
                    </v-card-title>
                </v-card>
                </v-flex>
            </v-layout>

        </v-container>

    </div>
</template>

<script>
  export default {
    data: () => ({
     serviceItems: [
       {description: 'Sanierung und Erhaltung', 
        to: '#sanierung',
        color:'#bed9ee'},
       {description: 'Spritzverfugung', 
        to: '#spritzverfugung',
        color: '#e9d1c5'},
       {description: 'Bauen', 
        to: '#bauen',
        color: '#f7ecac'},
       {description: 'Holzrestaurierung', 
        to: '#holzrestaurierung',
        color: '#e6878f'},
     ]
    }),
  }
</script>