<template>
    <v-container class="my-2 my-10 pt-16" id="kontakt">
            
      <v-row>
          
          <v-col cols="12" offset-lg="3" lg="6" offset-md="2" md="8" offset-sm="1" sm="10">
            <v-card
          class="mx-auto pa-2 my-2"
          height="100%"
          align="center"
          justify="center"
        >
        
        <!--<v-btn @click="testError()">Test Error</v-btn>-->

        <h1>Wie können wir weiterhelfen?</h1>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
            >
                <form @submit.prevent="submit">
                
                <v-container style="position: relative;">
                  <v-overlay :value="overlayForm">
                    <v-progress-circular
                      indeterminate
                      size="64"
                    ></v-progress-circular>
                  </v-overlay>

                    <v-row align="center">
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Name"
                                rules="required|max:100"
                            >
                                <v-text-field
                                dense
                                outlined
                                prepend-inner-icon="mdi-account"
                                v-model="name"
                                :error-messages="errors"
                                label="Name"
                                placeholder="Erika Mustermann"
                                required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col>
                             <v-text-field
                                dense
                                outlined
                                prepend-inner-icon="mdi-phone"
                                v-model="phoneNumber"
                                :error-messages="errors"
                                label="Tel. (optional)"
                                placeholder="05656 311"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <v-text-field
                                dense
                                outlined
                                prepend-inner-icon="mdi-email"
                                v-model="email"
                                :error-messages="errors"
                                label="E-Mail"
                                placeholder="erika.mustermann@fesch-bau.de"
                                required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col>
                            <v-textarea
                                dense
                                outlined
                                prepend-inner-icon="mdi-pencil"
                                v-model="text_message"
                                color="teal"
                                label="Ihre Nachricht"
                                placeholder="Guten Tag, ich habe eine Rückfrage zu ..."
                                >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col>
                        <validation-provider
                                v-slot="{ errors }"
                                name="Checkbox"
                                rules="required"
                            >
                          <!-- <v-checkbox 
                            v-model="checkbox"
                            required
                            :error-messages="errors"
                            label="Die Hinweise in der Datenschutzerklärung habe ich zur Kenntnis genommen."
                            ></v-checkbox> <br> -->
                          
                          <v-checkbox 
                            v-model="checkbox"
                            required
                            :error-messages="errors"
                            >
                            <template v-slot:label>
                              <div>
                                Ich habe die
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <a
                                      style="color: inherit;"
                                      target="_blank"
                                      href="/datenschutz"
                                      @click.stop
                                      v-on="on"
                                    >
                                      Datenschutzerklärung
                                    </a>
                                  </template>
                                  Datenschutzerklärung lesen
                                </v-tooltip>
                                zur Kenntnis genommen.
                              </div>
                            </template>
                          </v-checkbox>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col>
                          <v-btn
                            class="mr-4"
                            type="submit"
                            :disabled="invalid"
                        >
                            Senden
                        </v-btn>
                        <v-btn @click="clear">
                            Zurücksetzen
                        </v-btn>
                        </v-col>
                    </v-row>

                    <v-row justify="center" align="center" v-if="formSuccessful">
                      <v-col cols="2">
                        <v-icon size="40px" color="green">mdi-check-circle</v-icon>
                      </v-col>
                      <v-col cols="10">
                        Ihre Informationen wurden erfolgreich übertragen. Wir melden uns in Kürze bei Ihnen.
                      </v-col>
                    </v-row>
                </v-container>
                
                </form>
            </validation-observer>
            </v-card>
          </v-col>
          
      </v-row>
   

  
 </v-container>
</template>

<script>
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import axios from 'axios'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} muss  {length} Zeichen lang sein. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} bitte angeben',
  })

  extend('max', {
    ...max,
    message: '{_field_} darf nicht länger als {length} Zeichen sein',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} entspricht nicht {regex}',
  })

  extend('email', {
    ...email,
    message: 'Bitte gültige E-Mail-Adresse angeben.',
  })


  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
      name: '',
      phoneNumber: '',
      email: '',
      text_message: '',
      checkbox: null,
      formSending: false,
      formSuccessful: false,
      formError: false,
      overlayForm: false,
    }),

    methods: {
      
      testError () {
        throw new Error('Exception message');
      },
      submit () {
        this.$refs.observer.validate()
        this.overlayForm = true
        this.formSending = true
        this.text_message = this.text_message + '\n Tel Number: ' + this.phoneNumber
        const path = `https://api.fesch-bau.de/contact/${this.name}&${this.email}&${this.text_message}`
        console.log(path)
        axios.get(path
        ).then(() => {
          console.log('SUCCESS')
          this.formSending = false
          this.formSuccessful = true
          this.overlayForm = false
          this.clear()
        })
        .catch(function(){
          console.log('FAILURE')
          this.formError = false
          this.overlayForm = false
        })
      },
      clear () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.text_message = ''
        this.checkbox = false
        this.$refs.observer.reset()
      },
    },
  }
</script>