<template>
    <div id="bauen" class="pt-8">
        <v-container class="mt-5 mb-5">
            <h1>Bauen</h1>
            <p>Moderne Baustoffe und Bauverfahren ermöglichen eine kostengünstige, umweltgerechte energiebewusste und ansprechende Bauweise.</p>
        
            <v-layout row wrap class="mt-2">
                <v-flex v-for="item in serviceItems" :key="item.description" xs10 offset-xs1 offset-sm0 sm6 lg3 class="py-2">
                <v-card
                    class="ma-2"
                    height="100%"
                >
                    <v-img
                    :src="item.source"
                    ></v-img>

                    <v-card-text>
                        {{ item.description }}
                    </v-card-text>
                </v-card>
                </v-flex>
            </v-layout>

        </v-container>
    </div>
</template>

<script>
  export default {
    data: () => ({
     serviceItems: [
         //Vermauern von Lehmsteinen in einer Fachwerkkonstruktion
       {description: 'Für das Ausmauern von Fachwerk verwenden wir bevorzugt Lehmsteine, die mit Leisten im Fachwerk gehalten werden.', 
       source: require('../assets/bauen1.jpg')},
       {description: 'Erneuerung eines Schornsteinkopfes mit firmeneigenem Kranfahrzeug und einer Fertigteilkonstruktion spart Zeit und damit Kosten', 
       source: require('../assets/bauen2.jpg')},
       {description: 'Mit dem Minibagger, der mit einer speziellen Greifzange ausgerüstet ist, lassen sich z.B. schwere Natursteine effektiv versetzen.', 
       source: require('../assets/bauen3.jpg')},
       {description: 'Mit einer Vakuum-Platte, die an einem Kran hängt, lassen sich schwere Lasten (wie z.B. Treppenpodeste) mühelos bewegen.', 
       source: require('../assets/bauen4.jpg')}
     ]
    }),
  }
</script>