<template>
    <div id="holzrestaurierung" class="pt-8">
        <v-container class="mt-5 mb-5">
            <h1>Holzrestaurierung</h1>
            <p>Die Spezialisten von Fesch-Bau können etwaige Schäden nicht nur erkennen und beurteilen, sondern auch fachmännisch korrekt beheben. </p>
            
        <v-layout row wrap class="mt-2">
            <v-flex v-for="item in serviceItems" :key="item.description" xs10 offset-xs1 offset-sm0 sm6 lg3 class="py-2">
            <v-card
                class="ma-2"
                height="100%"
            >
                <v-img
                :src="item.source"
                ></v-img>

                <v-card-text>
                        {{ item.description }}
                    </v-card-text>

            </v-card>
            </v-flex>
        </v-layout>

        </v-container>
    </div>
</template>

<script>
  export default {
    data: () => ({
     serviceItems: [
       {description: 'Wer ein Fachwerkhaus besitzt, kennt das Problem.', 
       source: require('../assets/holzrestaurierung1.jpg')},
       {description: 'Der Zahn der Zeit nagt meist im Verborgenen.', 
       source: require('../assets/holzrestaurierung3.jpg')},
       //Während die Schadstellen an den Ausfachungen eher ins Auge fallen, werden zum Beispiel schleichende Fäulnisprozesse am Holz oft erst nach Jahren erkannt.
       {description: 'Die Schadstellen werden partiell erneuert.', 
       source: require('../assets/holzrestaurierung2.jpg')},
       //In solchen Fällen kann Abhilfe nur durch einen ausgewiesenen Spezialisten erfolgen.
       {description: 'Die Fachwerkerneuerung erstreckt sich hier über eine ganze Etage.', 
       source: require('../assets/holzrestaurierung4.jpg')},
     ]
    }),
  }
</script>